$(function() {
	$('.reviews-slider').not('.slick-initialized').slick({
		slidesToShow: 1,
		slidesToScroll: 1,
		infinite: true,
		arrows: false,
		dots: true,
		fade: true,
		cssEase: 'linear',
		responsive: [
	        {
	            breakpoint: 3200,
	            settings: {
					arrows: true,
					prevArrow: $('.reviews').find('.arrow-prev'),
					nextArrow: $('.reviews').find('.arrow-next'),
	            }
	        },
	        {
	            breakpoint: 992,
	            settings: {
					arrows: false,
	            }
	        },	        
		]
	});
	$('.firstly-in-kazakhstan-slider').not('.slick-initialized').slick({
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: true,
		infinite: true,
		cssEase: 'linear',	
		prevArrow: $('.firstly-in-kazakhstan').find('.arrow-left-wrap'),
		nextArrow: $('.firstly-in-kazakhstan').find('.arrow-right-wrap'),
	});

	$('.about-kazakhstan-slider').not('.slick-initialized').slick({
		slidesToShow: 1,
		slidesToScroll: 1,
		infinite: true,
		dots: false,
		fade: true,
		arrows: false,
		cssEase: 'linear',
		responsive: [
	        {
	            breakpoint: 3200,
	            settings: {
					arrows: true,
					dots: true,
					prevArrow: $('.about-kazakhstan').find('.arrows-vertical .arrow-left'),
					nextArrow: $('.about-kazakhstan').find('.arrows-vertical .arrow-right'),
	            }
	        },
	        {
	            breakpoint: 992,
	            settings: {
					arrows: true,
					dots: false,
					prevArrow: $('.about-kazakhstan').find('.arrows-horizontal .arrow-left'),
					nextArrow: $('.about-kazakhstan').find('.arrows-horizontal .arrow-right'),
	            }
	        },	        
		],
		afterChange: function(slider, currentSlide, nextSlide) {
			console.log(nextSlide);
		},
	});

	$('.about-kazakhstan-slider').on('init reInit afterChange', function(event, slick, currentSlide, nextSlide) {
		var i = (currentSlide ? currentSlide : 0) + 1;
		$('.about-kazakhstan').find('.current-slide').html(i);
	});

	if (isBreakpoint('xs') || isBreakpoint('sm')) {
		$('.almaty-places-slider').not('.slick-initialized').slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			infinite: true,
			arrows: false,
			cssEase: 'linear',
		});	
	} else {
		$first_almaty_places_item = $('.almaty-places-slide');
		$('.almaty-places-slider').find('.almaty-places-slide').each(function(index, item){
			$(item).height($($first_almaty_places_item).height())
		})
	}
});
