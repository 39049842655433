$(function() {
	
	function setCopyrightDate(){
  		year = new Date().getYear();
  		if (year<1900)
    		year+=1900;
  		$('#currentYear').html(year);
	}
	setCopyrightDate();

	$('.header-nav-burger-wrap').on('click', function(e) {
		$(this).parents('.header-info-wrap').find('.header-burger-content').stop().slideToggle();
		$(this).find('.header-nav-burger').toggleClass('isActive');
	});
	if (isBreakpoint('md') || isBreakpoint('lg')) {
		// setTimeout(function() {
		// 	$('.marilyn-photo-big').height($('.marilyn-skills-wrap').height());
		// }, 500);
		$('#circle-content .text').html($('#imptortant-elements-map area[data-number="0"]').data('text'));
		$('#circle-content .title').html($('#imptortant-elements-map area[data-number="0"]').data('title'));
		$('#imptortant-elements-map area').on('mouseover', function() {
			$hover_element = $('#imptortant-elements-circle-bg');
			degrees = $(this).data('number') * 30;
			$('#circle-content .text').html($(this).data('info'));
			$('#circle-content .title').html($(this).data('title'));
			$hover_element.css({'transform' : 'rotate('+ degrees +'deg)'});
			$hover_element.addClass('hover');
		})
	}
	$('.more-btn').click(function() {
		$(this).closest('.list-item').find('.more-info').slideToggle();
		if ($(this).text() == 'Подробнее') {
			$(this).text('Свернуть');
		} else {
			$(this).text('Подробнее');
		}
	});
	$('#fullPayModalForm').submit(function(e) {
		e.preventDefault();
		var form = $(this);
		
		var programDescription = 'Оплата за Тренинг Тренеров';

		var programPrice = parseInt($(this).find('input[name=price]').val());

		var email = $(this).find('input[name=email]').val();
		var phone = $(this).find('input[name=phone]').val();
		var name  = $(this).find('input[name=name]').val();

		if (programPrice > 0 && email && phone && name) {
			var widget = new cp.CloudPayments();
			widget.charge({ // options
				publicId : 'pk_3a168eca0de79a3c04dade999663c', //id из личного кабинета
				description : programDescription, //назначение
				amount : programPrice, //сумма
				currency : 'KZT', //валюта
				// invoiceId : '1234567', //номер заказа  (необязательно)
				accountId : email, //идентификатор плательщика (необязательно)
				data : {
					name : name, //произвольный набор параметров
					phone : phone //произвольный набор параметров
				}
			},
			function (options) { 
				pay_data = {
					name: options.data.name,
					phone: options.data.phone,
					price: options.amount,
					email: options.accountId,
				}
				$.ajax({
					url: '/send/full/',
					method: 'POST',
					data: pay_data,
					dataType: 'json',
					success: function(data) {
						if (data.redirect) {
							window.location.href = data.redirect;
						} else {
							form.html(data.html);
						}
					}
				});
			},
			function (reason, options) { // fail
				form.html('Ошибка!');
			});
		}
	});

	$('#prePayModalForm').submit(function(e) {
		e.preventDefault();
		var form = $(this);
		
		var programDescription = 'Предоплата за Тренинг Тренеров';

		var programPrice = parseInt($(this).find('input[name=price]').val());

		var email = $(this).find('input[name=email]').val();
		var phone = $(this).find('input[name=phone]').val();
		var name  = $(this).find('input[name=name]').val();

		if (programPrice > 0 && email && phone && name) {
			var widget = new cp.CloudPayments();
			widget.charge({ // options
				publicId : 'pk_3a168eca0de79a3c04dade999663c', //id из личного кабинета
				description : programDescription, //назначение
				amount : programPrice, //сумма
				currency : 'KZT', //валюта
				// invoiceId : '1234567', //номер заказа  (необязательно)
				accountId : email, //идентификатор плательщика (необязательно)
				data : {
					name : name, //произвольный набор параметров
					phone : phone //произвольный набор параметров
				}
			},
			function (options) { 
				pay_data = {
					name: options.data.name,
					phone: options.data.phone,
					price: options.amount,
					email: options.accountId,
				}
				$.ajax({
					url: '/send/pre/',
					method: 'POST',
					data: pay_data,
					dataType: 'json',
					success: function(data) {
						if (data.redirect) {
							window.location.href = data.redirect;
						} else {
							form.html(data.html);
						}
					}
				});
			},
			function (reason, options) { // fail
				form.html('Ошибка!');
			});
		}
	});	

	ajax_form();
	function ajax_form() {
		$('.ajax-form').submit(function(e) {
			e.preventDefault();
			var form = $(this);
	        $('button', form).each(function() {
    	        $(this).attr('data-old-title', $(this).text());
	            $(this).text('Отправляется');
	            $(this).addClass('disabled');
	        });
			$.ajax({
				url: form.attr('action'),
				method: form.attr('method'),
				data: form.serialize(),
				dataType: 'json',
				success: function(data) {
					if (data.redirect) {
						window.location.href = data.redirect;
					} else {
						form.find('.information').html(data.html);
		                $('button', form).each(function() {
		                    $(this).text($(this).data('old-title'));
		                    $(this).removeClass('disabled');
		                });
					}
				}
			});
		});
		$('.ajax-form-change').submit(function(e) {
		e.preventDefault();
		var form = $(this);
        $('button', form).each(function() {
    	    $(this).attr('data-old-title', $(this).text());
            $(this).text('Отправляется');
            $(this).addClass('disabled');
        });
		$.ajax({
				url: form.attr('action'),
				method: form.attr('method'),
				data: form.serialize(),
				dataType: 'json',
				success: function(data) {
					if (data.redirect) {
						window.location.href = data.redirect;
					} else {
						form.html(data.html);
		                $('button', form).each(function() {
		                    $(this).text($(this).data('old-title'));
		                    $(this).removeClass('disabled');
		                });
					}
					setTimeout(function() {
						form.closest('.modal').modal('hide');
					}, 3000);
				}
			});
		});		
	}
})
function isBreakpoint( alias ) {
    return $('.device-' + alias).is(':visible');
}
function cloudPayment() {
	// body...
}